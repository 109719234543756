
.ventana-subscripcion {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.ventana-subscripcion__contenido {
    width: 80%;
    display: grid;
    gap: 8px;
}

.ventana-subscripcion__contenido__logo {
    width: fit-content;
    height: 100px;
}

.ventana-subscripcion__contenido__descripcion {
    font-size: 'Roboto';
    font-size: 20px;
}

.ventana-subscripcion__contenido__precio {
    font-size: 'Roboto';
    font-size: 22px;
    font-weight: bold;
}

.ventana-subscripcion__ontenido__btn {
    width: fit-content;
    height: fit-content;
    padding: 12px;
    border-radius: 5px;
    background: #362AD8;
    cursor: pointer;
    font-weight: bold;
    font-size: 15px;
}

.ventana-subscripcion__ontenido__btn:hover {
    text-decoration: underline;
}

.ventana-subscripcion__icono {
    width: 130px;
    height: fit-content;
}

.seccion-membresias {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.seccion-membresias .seccion-membresias__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
}

.seccion-membresias__info .seccion-membresias__info__texto {
    font-size: 'Roboto';
    font-size: 16px;
}

.seccion-subscripciones {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.card-subscripcion {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 8px;
    /* border-bottom: 1px solid var(--color-primario); */
}
