

.contenido-modal {
    display: grid;
    gap: 20px;
}

.contenido-modal__icono {
    font-size: 50px;
    text-align: center;
}

.contenido-modal__mensaje {
    font-size: 18px;
    font-weight: 400;
    text-align: center;
}