.div-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.div-oculto {
    display: contents;
    transition: display 0.5s ease-in-out;
}

.checkbox {
    width: fit-content;
    cursor: pointer;
    user-select: none;
    font-size: 16px;
    color: var(--color-text-resaltado);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}

.div-flex-colum {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

.icono-info {
    color: var(--color-text-resaltado);
    font-size: 50px;
}

.texto-centrado {
    text-align: center;
    margin: 0;
    margin-bottom: 10px;
}